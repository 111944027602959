import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { green, pink, cyan, yellow } from '@material-ui/core/colors';
import { getTokenStatus, getUserInfo, logoutUser, getUserBalance } from './Api';

import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import Image from './img/lights-small.png';
import logoSmall from './img/logo-small.png';
import Profile from './Profile';
import MyGames from './MyGames';

import {
    Route,
    NavLink,
    BrowserRouter,
} from "react-router-dom";

import Games from "./Games";
import GamesTable from "./GamesTable";
import GameView from "./GameView";
import SignUp from "./SignUp";
import Login from "./Login";
import ForgotPassword from "./ForgotPassword";
import ResetPassword from "./ResetPassword";
import SignupBanner from './SignupBanner';
import UserMenu from './UserMenu';
import Account from './Account';
import Temp from './Temp';
import AddFunds from './AddFunds';
import WithdrawFunds from './WithdrawFunds';


// import green from '@material-ui/core/colors/green';

const theme = createMuiTheme({
    palette: {
        primary: {
            main: green[800],
        },
        secondary: {
            main: yellow[700],
        },
    }
});
const styles = styleFunc => ({
    toolBarImage: {
        backgroundImage: `url(${Image})`,
        backgroundSize: "cover",
    },
    icon: {
        marginRight: theme.spacing(2),
    },
    heroContent: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(8, 0, 6),
    },
    heroButtons: {
        marginTop: theme.spacing(4),
    },
    cardGrid: {
        paddingTop: theme.spacing(8),
        paddingBottom: theme.spacing(8),
    },
    card: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    cardMedia: {
        paddingTop: '56.25%', // 16:9
    },
    cardContent: {
        flexGrow: 1,
    },
    navLink: {
        textDecoration: 'none',
        color: 'white',
    },
    snackBarMessage: {
        padding: '100px',
    },
    balanceChip: {
        // backgrounColor: green[500],
        color: green[500],
    },
    footer: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(6),
    },
});

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="https://jambet.net/">
                JamBet.Net
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

class App extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            auth: false,
            username: null,
            accountBalance: null,
            firstName: null,
            lastName: null,
            email: null,
            authMessage: null,
            openSnackbox: false,
            snackboxMsg: null,
            anchorEl: null,
            myVar: 'hey',
        };
    }

    componentDidMount() {
        getTokenStatus(this)
            .then(() => {
                if(this.state.auth){
                    getUserInfo(this);
                }
            })
            .then(() => {this.setState({isLoaded: true})})
    }

    onLogin(msg){
        getTokenStatus(this).then(() => {
            if(this.state.auth){
                getUserInfo(this);
            }
        });
        this.setState({
            openSnackbox: true,
            snackboxMsg: msg,
        });
    }

    onProfileReload(){
        getUserInfo(this);
    }

    reloadBalance(){
        getUserBalance(this);
    }

    onSubmitEntry(){
        getUserInfo(this);
    }

    testSnackbar(){
        this.setState({
            openSnackbox: true,
            snackboxMsg: "Testing!",
        });
    }

    handleSnackbarClose(event, reason) {
        if (reason === 'clickaway') {
            return;
        }

        this.setState({
            openSnackbox: false,
            snackboxMsg: null,
        });
    };

    handleLogout(){
        logoutUser(this);
    }

    pageContent() {
        const { classes } = this.props;


        const loggedInControls = (
            <UserMenu
            username={this.state.username}
            accountBalance={this.state.accountBalance}
            handleLogout={this.handleLogout.bind(this)}
            />
        );

        const loggedOutControls = (
            <NavLink to="/login" className={classes.navLink}>
            <Button variant="contained" size="small">Login</Button>
            </NavLink>    
        );


        return (
            <React.Fragment>
                <CssBaseline />
                <BrowserRouter>
                    <AppBar position="relative" className={classes.toolBarImage}>
                        <Toolbar>
                            <Grid container justify="space-between" direction="row" alignItems="center" spacing={3}>
                                <Grid item>
                                <Typography variant="h6" color="inherit" noWrap>
                                    <NavLink to="/" className={classes.navLink}>
                                    <img src={logoSmall} />
                                    </NavLink>
                                </Typography>
                                </Grid>                                
                                <Grid item>
                                    {this.state.auth ? loggedInControls : loggedOutControls}
                                </Grid>
                            </Grid>
                        </Toolbar>
                    </AppBar>
                    <main>
                        {this.state.auth ? "" : <SignupBanner />}
                        <Route exact path="/" component={Games} />
                        <Route exact path="/gamestable" component={GamesTable} />
                        <Route
                            path="/gameview/:id"
                            render={(props) => <GameView {...props}
                                username={this.state.username}
                                auth={this.state.auth}
                                accountBalance={this.state.accountBalance}
                                onSubmitEntry={this.onSubmitEntry.bind(this)}
                            />}
                        />
                        <Route path="/signup/:code?" render={(props) => <SignUp {...props} onLogin={this.onLogin.bind(this)} />} />
                        <Route path="/login" render={(props) => <Login {...props} onLogin={this.onLogin.bind(this)} />} />
                        <Route path="/forgot-password" render={(props) => <ForgotPassword {...props} />} />
                        <Route path="/reset-password/:token" render={(props) => <ResetPassword {...props} />} />
                        <Route path="/profile" render={(props) => <Profile {...props}
                            username={this.state.username}
                            firstName={this.state.firstName}
                            lastName={this.state.lastName}
                            email={this.state.email}
                            onProfileReload={this.onProfileReload.bind(this)}
                        />} />
                        <Route path="/account" render={(props) => <Account {...props} onProfileReload={this.onProfileReload.bind(this)} username={this.state.username} balance={this.state.accountBalance} />} />
                        <Route path="/mygames" render={(props) => <MyGames {...props} onProfileReload={this.onProfileReload.bind(this)} username={this.state.username}/>} />
                        <Route path="/temp" component={Temp}></Route>
                        {/* <Route path="/addfunds" component={AddFunds}></Route> */}
                        <Route path="/addfunds" render={(props) => <AddFunds {...props} onProfileReload={this.onProfileReload.bind(this)} username={this.state.username}/>} />
                        <Route path="/withdrawfunds" render={(props) => <WithdrawFunds {...props} reloadBalance={this.reloadBalance.bind(this)} username={this.state.username} email={this.state.email}/>} />
                        {/* <Button variant="contained" onClick={this.testSnackbar.bind(this)}>Test</Button> */}
                    </main>

                    {/* Footer */}
                    <footer className={classes.footer}>
                        <Typography variant="h6" align="center" gutterBottom>
                            JamBet.Net
                        </Typography>
                        <Typography variant="subtitle1" align="center" color="textSecondary" component="p">
                        Fantasy setlist games for your favorite jam bands!
                        </Typography>
                        <Copyright />
                    </footer>
                    {/* End footer */}

                    <Snackbar 
                        open={this.state.openSnackbox} 
                        autoHideDuration={6000} 
                        onClose={this.handleSnackbarClose.bind(this)}
                        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}                        
                    >
                        <Alert severity="success" onClose={this.handleSnackbarClose.bind(this)}>
                        {this.state.snackboxMsg}
                        </Alert>
                    </Snackbar>
                    
                </BrowserRouter>
            </React.Fragment>
        );
    }

    render() {
        return (
            <React.Fragment>
                <ThemeProvider theme={theme}>
                {this.state.isLoaded ? this.pageContent() : ""}
                </ThemeProvider>
            </React.Fragment>
        );
    }
}

App.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(App);
