import React from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Chip from '@material-ui/core/Chip';
import Paper from '@material-ui/core/Paper';
import MonetizationOnOutlinedIcon from '@material-ui/icons/MonetizationOnOutlined';
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';
import { green } from '@material-ui/core/colors';
import { withStyles } from '@material-ui/styles';
import { NavLink, withRouter} from "react-router-dom";
import PropTypes from 'prop-types';
import {handleLogout} from './Api';
import Divider from '@material-ui/core/Divider';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import { createMuiTheme } from '@material-ui/core/styles';
import Moment from "react-moment";

const theme = createMuiTheme();

const styles = styleFunc => ({
    navLink: {
        textDecoration: 'none',
        color: 'white',
    },
    headerTable: {
        fontSize: '0.75rem',
        lineHeight: '0.50rem',
    },
});

const makeMoment = date => {
    return (
        <Moment format="MMM D, YYYY">
            {date}
        </Moment>
    );
}

class SmallTable extends React.Component {

    // constructor(props) {
    //     super(props);
    //     this.state = {
    //         anchorEl: null,
    //     };
    // }

    getRowColor = value => {
        switch (value) {
            case 1:
                return '#ffa50066'; // Light orange
            case 2:
                return '#00800033'; // Light green
            default:
                return 'white';
        }
    }


    render(){
        const { classes, headers, data, showHeaders, minWidth } = this.props;

        const tableHead = (
            <TableHead>
                <TableRow>
                    {headers.map(col => {
                        return (<TableCell align={col.align} className={classes.headerTable} style={{minWidth: theme.spacing(minWidth)}}>
                                    <Typography variant='overline' className={classes.headerTable}><b>{col.name}</b></Typography>
                                </TableCell>);
                    })}
                </TableRow>
            </TableHead>
        );

        return (
            <>
            <TableContainer component={Paper}>
                <Table size="small">
                    {showHeaders ? tableHead : ""}
                    <TableBody>
                        {data.map((row, index) => {
                            const rowColor = this.getRowColor(row.win); // Adjust 'row.value' to match your data structure
                            return (<TableRow key={index} style={{backgroundColor: rowColor}}>
                                {headers.map(col => {
                                    return (<TableCell align={col.align} className={classes.headerTable} style={{minWidth: theme.spacing(minWidth)}}>
                                        <Typography variant='overline' color={col.color} className={classes.headerTable}>
                                            {(row.isDate && col.key !== 'label') ? makeMoment(row[col.key]) : row[col.key]}
                                        </Typography>
                                    </TableCell>);
                                })}
                            </TableRow>);
                        })}
                    </TableBody>


                    {/* <TableBody>
                        {data.map((row, index) => {
                            return (<TableRow key={index} >
                                {headers.map(col => {
                                    return (<TableCell align={col.align} className={classes.headerTable} style={{minWidth: theme.spacing(minWidth)}}>
                                        <Typography variant='overline' color={col.color} className={classes.headerTable}>
                                            {(row.isDate && col.key != 'label') ? makeMoment(row[col.key]) : row[col.key]}
                                        </Typography>
                                    </TableCell>);
                                })}
                            </TableRow>);
                        })}
                    </TableBody> */}
                </Table>
            </TableContainer>

            </>    
        );
    }
}

SmallTable.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(withRouter(SmallTable));
