import React from 'react';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/styles';
import { createMuiTheme } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { withRouter } from "react-router";
import {getUserEntries} from './Api';
import EnhancedTable from './EnhancedTable';

const theme = createMuiTheme();
const styles = styleFunc => ({
    mainGrid: {
        paddingTop: theme.spacing(8),
        paddingBottom: theme.spacing(8),
    },
    mainPaper: {
        textAlign: 'center',
        paddingTop: "20px",
        paddingBottom: "20px",
        paddingLeft: "50px",
        paddingRight: "50px",
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(3),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
});

class MyGames extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            // username: this.props.username,
            activeGames: [],
            doneGames: [],
        };
    }

    componentDidMount(){
        getUserEntries(this, this.props.username);
    }

    render() {

        const { classes } = this.props;
        
        // const rows = [{name: 'Cupcake', calories: 123, fat: 456, carbs: 789, protein: 100}];
        console.log('active games = ' + JSON.stringify(this.state.activeGames));
        const headCells = [
            // { id: 'game_id', numeric: false, label: 'Game ID' },
            { id: 'show_date', numeric: false, date: true, label: 'Date' },
            { id: 'show_venue', numeric: false, date: false, label: 'Venue' },
            { id: 'show_location', numeric: false, date: false, label: 'Location' },
            { id: 'game_type', numeric: false, date: false, label: 'Game' },
            { id: 'status', numeric: false, date: false, label: 'Status' },
          ];
        
        const activeTable = (
            <EnhancedTable rows={this.state.activeGames} headCells={headCells} history={this.props.history} />
        );

        const previousTable = (
            <EnhancedTable rows={this.state.doneGames} headCells={headCells} history={this.props.history} />
        );

        const noActiveGames = (
            <>
                <Typography variant="body1" color="primary">
                <i>You have no active games.</i>
                </Typography>
                <Box my={2}></Box>
            </>
        )

        const noPreviousGames = (
            <>
                <Typography variant="body1" color="primary">
                <i>You have no completed games.</i>
                </Typography>
            </>
        )

        return (
            <>
                <Container className={classes.mainGrid}>
                    <Grid container spacing={3} justify='center'>
                        <Grid item xs={12}>
                            <Paper className={classes.mainPaper}>
                                <Typography variant="h6" gutterBottom className={classes.formHeader}>
                                Active Games
                                </Typography>
                                {this.state.activeGames.length > 0 ? activeTable : noActiveGames}
                                <Divider />
                                <Box my={2}></Box>
                                <Typography variant="h6" gutterBottom className={classes.formHeader}>
                                Previous Games
                                </Typography>
                                {this.state.doneGames.length > 0 ? previousTable : noPreviousGames}
                            </Paper>
                        </Grid>
                    </Grid>
                </Container>
            </>
        );
    }
}

MyGames.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(withRouter(MyGames));



