import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import {Alert, AlertTitle} from '@material-ui/lab';

import {
  NavLink
} from "react-router-dom";


import {withStyles} from '@material-ui/styles';
import {createMuiTheme} from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import {withRouter} from "react-router";
import {createUser} from './Api';
import {usernameExists} from "./Api"; // Import your function


const theme = createMuiTheme();

const styles = styleFunc => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
});

class SignUp extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      firstName: null,
      lastName: null,
      activationCode: null,
      email: null,
      username: "",
      usernameError: "",
      isUsernameValid: true,
      password: null,
      message: '',
    };
    this.usernameDebounceTimer = null; // Timer for debouncing
  }

  validateUsername = (username) => {
    // Check length
    if (username.length < 3) {
      this.setState({
        usernameError: "Username must be at least 3 characters long.",
        isUsernameValid: false,
      });
      return;
    }

    // Check valid characters (alphanumeric, dashes, underscores)
    const regex = /^[a-zA-Z0-9_-]+$/;
    if (!regex.test(username)) {
      this.setState({
        usernameError: "Username can only contain letters, numbers, dashes, and underscores.",
        isUsernameValid: false,
      });
      return;
    }

    // Call the API to check if the username exists
    usernameExists(this, username).then(() => {
      if (this.state.usernameExists) {
        this.setState({
          usernameError: "This username is already taken. Please choose another.",
          isUsernameValid: false,
        });
      } else {
        this.setState({
          usernameError: "Username " + username + " is available!",
          isUsernameValid: true,
        });
      }
    });
  };

  handleUsernameChange = (e) => {
    const username = e.target.value;
    this.setState({username});

    // If the username is blank, clear errors and validation state
    if (username.trim() === "") {
      this.setState({
        usernameError: "",
        isUsernameValid: true,
      });
      if (this.usernameDebounceTimer) {
        clearTimeout(this.usernameDebounceTimer); // Clear any pending debounce
      }
      return;
    }

    // Set a new debounce timer
    this.usernameDebounceTimer = setTimeout(() => {
      this.validateUsername(username);
    }, 500); // 500ms delay
    // this.validateUsername(username);
  };

  componentDidMount() {
    // Get the activation code from the URL
    const {code} = this.props.match.params;
    this.setState({activationCode: code || ''});
  }

  handleSubmit(event) {
    event.preventDefault();
    createUser(this);
  }


  render() {
    const {classes} = this.props;

    var msgAlert = (
      <>
        <Box mt={3}></Box>
        <Alert severity="error">
          <AlertTitle>Error</AlertTitle>
          {this.state.message}
        </Alert>
        <Box mt={3}></Box>
      </>
    );

    return (
      <Container component="main" maxWidth="xs">
        <CssBaseline/>
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon/>
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign up
          </Typography>
          {/*<Alert severity="info">*/}
          {/*  <AlertTitle>INFO</AlertTitle>*/}
          {/*  JamBet is in Beta mode, you will need an activation code to sign up.*/}
          {/*</Alert>*/}

          <form className={classes.form} noValidate onSubmit={this.handleSubmit.bind(this)}>
            {this.state.message === '' ? '' : msgAlert}

            <Grid container spacing={2}>
              {/*<Grid item xs={12}>*/}
              {/*  <TextField*/}
              {/*    variant="outlined"*/}
              {/*    required*/}
              {/*    fullWidth*/}
              {/*    id="activationCode"*/}
              {/*    label="Activation Code"*/}
              {/*    name="activationCode"*/}
              {/*    value={this.state.activationCode}*/}
              {/*    onChange={(e) => this.setState({activationCode: e.target.value})}*/}
              {/*  />*/}
              {/*</Grid>*/}

              <Grid item xs={12} sm={6}>
                <TextField
                  autoComplete="fname"
                  name="firstName"
                  variant="outlined"
                  fullWidth
                  id="firstName"
                  label="First Name"
                  autoFocus
                  onChange={e => this.setState({firstName: e.target.value})}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  variant="outlined"
                  fullWidth
                  id="lastName"
                  label="Last Name"
                  name="lastName"
                  autoComplete="lname"
                  onChange={e => this.setState({lastName: e.target.value})}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  onChange={e => this.setState({email: e.target.value})}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="username"
                  label="Username"
                  name="username"
                  autoComplete="username"
                  value={this.state.username}
                  onChange={this.handleUsernameChange}
                  error={!this.state.isUsernameValid}
                  helperText={this.state.usernameError || "Your username will be visible to the public."}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                  onChange={e => this.setState({password: e.target.value})}
                />
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              Sign Up
            </Button>
            <Grid container justify="flex-end">
              <Grid item>
                <NavLink to="/login" variant="body2">
                  Already have an account? Sign in
                </NavLink>
              </Grid>
            </Grid>
          </form>
        </div>
        <Box mt={5}>
        </Box>
      </Container>
    );
  }
}

SignUp.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(withRouter(SignUp));
