import React from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/styles';
import { createMuiTheme } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { withRouter } from "react-router";
import { NavLink } from "react-router-dom";
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import {updateUser, getUserInfo, responseCodes} from './Api';
import { Alert, AlertTitle } from '@material-ui/lab';

const theme = createMuiTheme();
const styles = styleFunc => ({
    mainGrid: {
        paddingTop: theme.spacing(8),
        paddingBottom: theme.spacing(8),
    },
    mainPaper: {
        textAlign: 'center',
        paddingTop: "20px",
        paddingBottom: "20px",
        paddingLeft: "50px",
        paddingRight: "50px",
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(3),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
});

function copyString(value){
    return JSON.parse(JSON.stringify(value === null ? '' : value));
}

class Profile extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            username: copyString(this.props.username),
            firstName: copyString(this.props.firstName),
            lastName: copyString(this.props.lastName),
            email: copyString(this.props.email),
            password: null,
            submitResponse: null,
        };
    }
    
    componentDidMount(){
        getUserInfo(this);
    }

    handleSubmit(event){
        event.preventDefault();
        updateUser(this).then(() => {
            console.log(this.state.submitResponse);
        });

    }


    render() {

        const { classes } = this.props;

        const updateProfileForm = (
            <form className={classes.form} onSubmit={this.handleSubmit.bind(this)}>

            <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                    <TextField
                        autoComplete="fname"
                        name="firstName"
                        variant="outlined"
                        fullWidth
                        id="firstName"
                        label="First Name"
                        value={this.state.firstName}
                        autoFocus
                        onChange={e => this.setState({ firstName: e.target.value })}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        variant="outlined"
                        fullWidth
                        id="lastName"
                        label="Last Name"
                        name="lastName"
                        autoComplete="lname"
                        value={this.state.lastName}
                        onChange={e => this.setState({ lastName: e.target.value })}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        variant="outlined"
                        required
                        fullWidth
                        id="email"
                        label="Email Address"
                        name="email"
                        autoComplete="email"
                        value={this.state.email}
                        onChange={e => this.setState({ email: e.target.value })}
                    />
                </Grid>
                {/* <Grid item xs={12}>
                    <Divider />
                </Grid> */}
                <Grid item xs={12}>
                    <TextField
                        variant="outlined"
                        fullWidth
                        name="password"
                        label="New Password"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                        onChange={e => this.setState({ password: e.target.value })}
                    />
                </Grid>
            </Grid>
            <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
            >
                Updated Info
            </Button>
            <Button component={NavLink} to="/">Cancel</Button>
        </form>
        );

        var msgAlert = (
            <>
            <Alert severity="error">
                <AlertTitle>Error</AlertTitle>
                There was a problem updating your profile.
            </Alert>
            <Box mt={3}></Box>
            <Button variant="contained" component={NavLink} to="/">Return</Button>
            </>
        );

        if (this.state.submitResponse === responseCodes.USER.SUCCESS) {
            msgAlert = (
                <>
                <Alert severity="success">
                    <AlertTitle>Success</AlertTitle>
                    Profile successfully updated.
                </Alert>
                <Box mt={3}></Box>
                <Button variant="contained" component={NavLink} to="/">Return</Button>
                </>
            );
        }


        return (
            <>
                <Container className={classes.mainGrid}>
                    <Grid container spacing={3} justify='center'>
                        <Grid item xs={12} sm={10} md={8} lg={6}>
                            <Paper className={classes.mainPaper}>
                                <Typography variant="h6" gutterBottom className={classes.formHeader}>
                                    Update {this.props.username}'s Profile
                                </Typography>
                                {this.state.submitResponse === null ? updateProfileForm : msgAlert}
                            </Paper>
                        </Grid>
                    </Grid>
                </Container>
            </>
        );
    }
}

Profile.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(withRouter(Profile));
