import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';

import {withStyles} from '@material-ui/styles';
import {createMuiTheme} from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import {withRouter} from "react-router";
import {NavLink} from 'react-router-dom';
import {Alert, AlertTitle} from '@material-ui/lab';

import {resetPassword} from './Api';

const theme = createMuiTheme();
const styles = styleFunc => ({

  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
});

class ResetPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      password: '',
      message: '',
      passwordReset: false,
    };
  }

  handleSubmit(event) {
    event.preventDefault();
    resetPassword(this, this.props.match.params.token);
  }

  render() {

    const {classes} = this.props;

    var msgAlert = (
      <>
        <Box mt={3}></Box>
        <Alert severity="error">
          <AlertTitle>Error</AlertTitle>
          {this.state.message === 'EXPIRED_PW_RESET' ? (
            <>
              Password reset link expired! Please{' '}
              <a href="/forgot-password" style={{textDecoration: 'underline', color: 'blue'}}>
                request a new one
              </a>.
            </>
          ) : (
            this.state.message
          )}
        </Alert>
        <Box mt={3}></Box>
      </>
    );

    const resetPasswordForm = (
      <form className={classes.form} noValidate onSubmit={this.handleSubmit.bind(this)}>
        {this.state.message === '' ? '' : msgAlert}
        <TextField
          variant="outlined"
          required
          fullWidth
          name="password"
          label="Password"
          type="password"
          id="password"
          autoComplete="current-password"
          onChange={e => this.setState({password: e.target.value})}
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
        >
          Reset Password
        </Button>
      </form>
    );

    const submittedMessage = (
      <Typography component="h1" style={{textAlign: 'center', fontStyle: 'italic', paddingTop: '20px'}}>
        Password has been reset!
        <NavLink to="/login" variant="body2">Sign in</NavLink>
      </Typography>
    );

    return (
      <Container component="main" maxWidth="xs">
        <CssBaseline/>
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon/>
          </Avatar>
          <Typography component="h1" variant="h5">
            Reset Password
          </Typography>
          {this.state.passwordReset ? submittedMessage : resetPasswordForm}
        </div>
        <Box mt={8}>
        </Box>
      </Container>
    );
  }
}

ResetPassword.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(withRouter(ResetPassword));
