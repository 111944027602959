import React from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Chip from '@material-ui/core/Chip';
import MonetizationOnOutlinedIcon from '@material-ui/icons/MonetizationOnOutlined';
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';
import { green } from '@material-ui/core/colors';
import { withStyles } from '@material-ui/styles';
import { NavLink, withRouter} from "react-router-dom";
import PropTypes from 'prop-types';
import {handleLogout} from './Api';
import Divider from '@material-ui/core/Divider';

const styles = styleFunc => ({
    navLink: {
        textDecoration: 'none',
        color: 'white',
    },
    balanceChip: {
        color: green[500],
    },
});

class UserMenu extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            anchorEl: null,
        };
    }

    handleMenuClick(event) {
        this.setState({anchorEl: event.currentTarget});
    }

    handleMenuClose() {
        this.setState({anchorEl: null});
    }

    handleLogout() {
        this.setState({anchorEl: null});
        // logoutUser(this);
        this.props.handleLogout();
    }

    render(){
        const { classes } = this.props;
        const balance = this.props.accountBalance == null ? "0.00" : this.props.accountBalance.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');

        return (
            <>
            <Grid container spacing={1} alignItems="center">
                <Grid item>
                    <Chip size="small" icon={<MonetizationOnOutlinedIcon />} label={balance} className={classes.balanceChip} />
                </Grid>
                <Grid item>    
                    <Button color="secondary" variant="contained" size="small" aria-controls="simple-menu" aria-haspopup="true" onClick={this.handleMenuClick.bind(this)}>
                    <AccountCircleOutlinedIcon fontSize="small"/>&nbsp;{this.props.username}
                    </Button>
                    <Menu
                        id="simple-menu"
                        anchorEl={this.state.anchorEl}
                        keepMounted
                        open={Boolean(this.state.anchorEl)}
                        onClose={this.handleMenuClose.bind(this)}
                        getContentAnchorEl={null}
                        anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
                    >
                        <MenuItem onClick={this.handleMenuClose.bind(this)} component={NavLink} to="/profile">Profile</MenuItem>
                        <MenuItem onClick={this.handleMenuClose.bind(this)} component={NavLink} to="/account">My Account</MenuItem>
                        <MenuItem onClick={this.handleMenuClose.bind(this)} component={NavLink} to="/mygames">My Games</MenuItem>
                        <Divider />
                        <MenuItem onClick={this.handleMenuClose.bind(this)} component={NavLink} to="/addfunds">Add Funds</MenuItem>
                        <MenuItem onClick={this.handleMenuClose.bind(this)} component={NavLink} to="/withdrawfunds">Withdraw Funds</MenuItem>
                        <Divider />
                        <MenuItem onClick={this.handleLogout.bind(this)} component={NavLink} to="/">Logout</MenuItem>
                    </Menu>
                </Grid>
                {/* <Grid item>
                    <NavLink to="/" className={classes.navLink}>
                    <Button variant="contained" size="small" color="secondary" onClick={this.props.handleLogout}>Logout</Button>
                    </NavLink>    
                </Grid> */}
            </Grid>
            </>    
        );
    }
}

UserMenu.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(withRouter(UserMenu));
