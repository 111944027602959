import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Container from '@material-ui/core/Container';
import { withStyles } from '@material-ui/styles';
import { createMuiTheme } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { withRouter } from "react-router";
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import { Alert, AlertTitle } from '@material-ui/lab';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import { deepOrange } from '@material-ui/core/colors';
import {NavLink} from "react-router-dom";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Box from '@material-ui/core/Box';
import Moment from 'react-moment';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import SmallTable from './SmallTable';
import grey from '@material-ui/core/colors/grey';

import {getGame, getSongs, submitGameEntry, responseCodes, getUserGameEntry, cancelGameEntry} from './Api';

const theme = createMuiTheme();
const styles = styleFunc => ({

    cardGrid: {
        paddingTop: theme.spacing(8),
        paddingBottom: theme.spacing(8),
    },
    formHeader: {
        textAlign: 'center',
    },
    submitButton: {
        textAlign: 'right',
    },
    navButton: {
        textDecoration: 'none',
    },
    paper: {
        padding: theme.spacing(2),
        width: '100%', // Ensure Paper spans full width
        boxSizing: 'border-box',
    },
    orange: {
        color: theme.palette.getContrastText(deepOrange[500]),
        backgroundColor: deepOrange[500],
      },
    empty: {
        fontStyle: 'italic',
    },
    headerTable: {
        fontSize: '0.75rem',
        lineHeight: '0.50rem',
    },
    table: {
        // maxWidth: '75%',
        // minWidth: '75%',
        // width: '100%',
    },
    [`@media (max-width: 600px)`]: { // Media query for small screens
        paper: {
            padding: theme.spacing(1),
        },
    }
});

class GameView extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            game: {},
            songs: [],
            selectedSongs: [],
            formSubmitted: false,
            formResponse: {},
            alreadyEntered: false,
            existingEntries: [],
            isEditing: false,
            showCancelDialog: false,
            sufficientFunds: false,
        };
    }

    async componentDidMount() {
        // getSongs(this);
        // getGame(this, this.props.match.params.id)
        // getUserGameEntry(this, this.props.username, this.props.match.params.id).then(() => {
        //     this.setStartingEntries();
        // });
        await getSongs(this);

        // Wait for getGame to complete before calling getUserGameEntry
        await getGame(this, this.props.match.params.id);

        // Then call getUserGameEntry and wait for it to complete
        await getUserGameEntry(this, this.props.username, this.props.match.params.id);

        // Finally, set starting entries
        this.setStartingEntries();
    }

    setStartingEntries(){
        const songs = this.state.selectedSongs;
        console.log("setStartingEntries: state=" + JSON.stringify(this.state) +  " songs=" + JSON.stringify(songs))
        if(this.state.alreadyEntered){
            this.state.existingEntries.map((song, i) => {
                songs[i] = {id: song.id, name: song.name, artist: song.artist};
            });    
        } else {
            for(let i = 0; i < this.state.game.number_items; i++){
                songs[i] = null;
            }
        }
        this.setState({selectedSongs: songs});
        // console.log("setStartingEntries: state=" + JSON.stringify(this.state) +  " songs=" + JSON.stringify(songs))
        this.setState({sufficientFunds: this.props.accountBalance >= this.state.game.entry_fee});
        console.log("balance=" + this.props.accountBalance + " entry_fee=" + this.state.game.entry_fee + " sufficient_balance=" + this.state.sufficientFunds);
        // console.log("props = " + JSON.stringify(this.props) + " game = " + JSON.stringify(this.state.game));
    }

    setSongChoices(id, value) {
        const selectedSongs = this.state.selectedSongs;
        selectedSongs[id] = value;
        this.setState({ selectedSongs });
    }

    handleSubmit(event) {
        event.preventDefault();
        submitGameEntry(
            this,
            this.props.match.params.id,
            this.props.username,
            this.state.selectedSongs.map(song => ({ "song_id": song.id, "score": null, "category": null })),
            this.state.alreadyEntered,
        ).then(() => {
            getGame(this, this.props.match.params.id);
        }).then(() => {
            this.props.onSubmitEntry();
        });
    }

    setIsEditing(event){
        this.setState({isEditing: true});
    }

    setNotEditing(event){
        this.setState({isEditing: false});
        this.setStartingEntries();
    }

    openCancelDialog(){
        this.setState({showCancelDialog: true});
    }

    closeCancelDialog(answer){
        if(answer == 'yes'){
            cancelGameEntry(this, this.props.match.params.id, this.props.username).then(() => {
                getGame(this, this.props.match.params.id);
            }).then(() => {
                this.props.onSubmitEntry();
            });
        }
        this.setState({showCancelDialog: false});
    }

    render() {
        const { classes } = this.props;


        // if no previous entry, show fields and submit cancel, unless not logged in
        // if previous entry, show fields grayed out, edit & cancel entry
        var disabled = false;
        if(!this.props.auth || (this.state.alreadyEntered && !this.state.isEditing) || !this.state.sufficientFunds){
            disabled = true;
        }

        // NEW STUFF
        const fields = this.state.selectedSongs.map((song, i) => (
            <Grid item xs={12}>
                <Autocomplete
                    id={"song" + (i + 1)}
                    options={this.state.songs}
                    getOptionLabel={option => option.artist === 'Phish' ? option.name : option.name + ' (' + option.artist + ')'}
                    onChange={(e, v) => this.setSongChoices(i, v)}
                    autoSelect
                    autoHighlight
                    disableOpenOnFocus
                    value={song}
                    {...{ disabled: disabled }}
                    renderInput={params => (
                        <TextField {...params} label={"Song " + (i + 1)} variant="outlined" fullWidth />
                    )}
                />
            </Grid>

        ));
        // END NEW STUFF

        const editButtons = (
            <>
            <Grid item>
                <Button type="button" variant="contained" color="primary" onClick={this.setIsEditing.bind(this)}>Edit Entry</Button>
            </Grid>
            <Grid item>
                <Button type="button" variant="contained" color="secondary" onClick={this.openCancelDialog.bind(this)}>Cancel Entry</Button>
            </Grid>
            </>
        )

        const submitCancelFirst = (
            <>
            <Grid item>
                <Button type="submit" variant="contained" color="primary" className={classes.submitButton} {...{ disabled: disabled }}>Submit</Button>
            </Grid>
            <Grid item>
                {/* <NavLink to="/" className={classes.navButton}>
                    <Button type="button" variant="contained" {...{ disabled: disabled }}>Cancel</Button>
                </NavLink> */}
            </Grid>
            </>
        );

        const submitCancelEdit = (
            <>
            <Grid item>
                <Button type="submit" variant="contained" color="primary" className={classes.submitButton} {...{ disabled: disabled }}>Submit</Button>
            </Grid>
            <Grid item>
                <Button type="button" variant="contained" {...{ disabled: disabled }} onClick={this.setNotEditing.bind(this)}>Cancel</Button>
            </Grid>
            </>
        );

        const formComponent = (
            <form onSubmit={this.handleSubmit.bind(this)}>
                <Grid container spacing={3} justify="center">
                    {(this.state.alreadyEntered && disabled && this.state.game.status == 'Open') ? editButtons: ''}
                    {fields}
                    {this.state.isEditing ? submitCancelEdit : submitCancelFirst}
                </Grid>
            </form>
        );

        const cancelDialog = (
            <>
            <Dialog open={this.state.showCancelDialog}>
                <DialogTitle>{"Are you sure you want to cancel your entry?"}</DialogTitle>
                <DialogActions>
                <Button onClick={this.closeCancelDialog.bind(this, 'no')} color="primary">
                    No
                </Button>
                <Button onClick={this.closeCancelDialog.bind(this, 'yes')} color="primary" autoFocus>
                    Yes
                </Button>
                </DialogActions>
            </Dialog>
            </>
        )

        var msgAlert = (
            <Alert severity="error">
                <AlertTitle>Error</AlertTitle>
                {this.state.formResponse.message}
            </Alert>
        );

        var insufficientFundsMessage = (
            <Alert severity="warning">
                <AlertTitle>Warning</AlertTitle>
                You have insufficient funds in your account to submit this entry.
            </Alert>
        );

        if (this.state.formResponse.response_code === responseCodes.GAME.SUCCESS) {
            msgAlert = (
                <Alert severity="success">
                    <AlertTitle>Success</AlertTitle>
                    {this.state.formResponse.message}
                </Alert>
            );
        }

        const pleaseLogin = (
            <Alert severity="info" style={{ marginTop: "20px", marginBottom: "20px" }}>
                <AlertTitle>Login</AlertTitle>
                Please login or Sign Up to enter the game!
            </Alert>
        );

        const responseMessage = (
            <Grid container justify="center" direction="column" alignItems="center" spacing={3}>
                <Grid item>
                    {msgAlert}
                </Grid>
                <Grid item>
                    <NavLink to="/" className={classes.navButton}><Button type="button" variant="contained">Return to Games</Button></NavLink>
                </Grid>
            </Grid>
        );

        // var gameUsers = this.state.game.user_entries;
        // gameUsers = ( typeof gameUsers != 'undefined') ? gameUsers : []
        // const userEntries = [];
        // for(let i = 0; i < gameUsers.length; i++){
        //     userEntries.push(
        //         <ListItem divider >
        //             <ListItemAvatar>
        //                 <Avatar className={classes.orange}>
        //                 {gameUsers[i].charAt(0)}
        //                 </Avatar>
        //             </ListItemAvatar>
        //             <ListItemText primary={gameUsers[i]} />
        //         </ListItem>
        //     );
        // }
        // const maxEntries = this.state.game.max_entries;
        // const openEntries = ( typeof maxEntries === 'undefined') ? 0 : (maxEntries - userEntries.length);
        // for(let i = 0; i < openEntries; i++){
        //     userEntries.push(
        //         <ListItem divider>
        //             <ListItemAvatar>
        //                 <Avatar></Avatar>
        //             </ListItemAvatar>
        //             <ListItemText primary="empty" className={classes.empty}/>
        //         </ListItem>
        //     );
        // }

        //                 Entry Form = {this.state.game.game_type} - {this.state.game.artist} - {this.state.game.date}

        const infoHeaders = [
            {key: 'label', name: 'Label', align: 'left', color: grey[900]},
            {key: 'value', name: 'Value', align: 'right', color: 'primary'},
        ];
        const infoData = [
            {label: 'Artist', value: this.state.game.artist},
            {label: 'Show Date', value: this.state.game.date, isDate: true},
            {label: 'Game Type', value: this.state.game.game_type},
            {label: 'Game Status', value: this.state.game.status},
            {label: 'Entry Fee', value: '$' + this.state.game.entry_fee},
        ];

        const entryHeaders = [
            {key: 'username', name: 'User', align: 'left', color: grey[900]},
            {key: 'score', name: 'Score', align: 'center', color: 'primary'},
        ];

        var entryData = [];
        if(this.state.game.user_entries != undefined){
            entryData = this.state.game.user_entries;
        }
        // const entryData = [
        //     {username: 'tela', score: 20},
        //     {username: 'heybob', score: 19},
        // ];

        const gameHeader = (
            <>
            <Typography variant="h6" gutterBottom className={classes.formHeader}>
                Game Entry Ticket
            </Typography>

            <SmallTable headers={infoHeaders} data={infoData} showHeaders={false} />

          </>
        );

        return (
            <React.Fragment>
                {cancelDialog}
                <Container className={classes.cardGrid}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                            <Paper className={classes.paper}>
                                <Grid container spacing={3} direction="column" alignItems="center">
                                    <Grid item>
                                        {gameHeader}                                    
                                    </Grid>
                                    <Grid item>
                                        {this.props.auth ? "" : pleaseLogin}
                                      {(this.state.sufficientFunds || !this.props.auth) ? "" : insufficientFundsMessage}
                                        {this.state.formSubmitted === true ? responseMessage : formComponent}
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <Paper className={classes.paper}>
                                <div  className={classes.formHeader}>
                                    <Typography variant="h6" gutterBottom>
                                        Current Game Entries
                                    </Typography>
                                    <Typography variant="body1">
                                        Status: {this.state.game.status}
                                    </Typography>
                                    <Typography variant="body1">
                                        Entries: {this.state.game.entries } / {this.state.game.max_entries}
                                    </Typography>
                                </div>
                                <Box my={3}></Box>
                                <SmallTable headers={entryHeaders} data={entryData} showHeaders={true} />
                                {/* <List className={classes.root} dense>
                                    {userEntries}
                                </List> */}
                            </Paper>
                        </Grid>

                    </Grid>
                </Container>
            </React.Fragment>
        );
    }
}

GameView.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(withRouter(GameView));
