import React from 'react';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/styles';
import { createMuiTheme } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { withRouter } from "react-router";
import {NavLink} from "react-router-dom";
import { PayPalButton } from "react-paypal-button-v2";
//import { PayPalButton } from 'react-paypal-button'
// import SmartPaymentButtons from 'react-smart-payment-buttons';
import { Alert, AlertTitle } from '@material-ui/lab';

import {addFunds} from './Api';
import { type } from 'os';

const paypal_client_id = process.env.REACT_APP_PAYPAL_CLIENT_ID
const theme = createMuiTheme();
const styles = styleFunc => ({
    mainGrid: {
        paddingTop: theme.spacing(8),
        paddingBottom: theme.spacing(8),
    },
    mainPaper: {
        textAlign: 'center',
        paddingTop: "20px",
        paddingBottom: "20px",
        paddingLeft: "50px",
        paddingRight: "50px",
        width: '100%', // Fix IE 11 issue.
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(3),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
});

class AddFunds extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            username: this.props.username,
            fundsToAdd: 25,
            submitResponse: null,
            showMessage: false,
            fundsAdded: 0,
        };
    }

    render() {

        const { classes } = this.props;
        
        this.state.fundsAdded = parseFloat(this.state.fundsAdded);
        const funds = this.state.fundsAdded == null ? "0.00" : this.state.fundsAdded.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');

        const successAlert = (
            <>
            <Box mt={3}></Box>
            <Alert severity="success">
                <AlertTitle>Success</AlertTitle>
                ${funds} was successfully added to your account.
            </Alert>
            <Box mt={3}></Box>
            </>
        );

        const accountForm = (
            <form className={classes.form}>

            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TextField
                        // autoComplete="fname"
                        name="fundsToAdd"
                        variant="outlined"
                        fullWidth
                        id="fundsToAdd"
                        label="Funds To Add"
                        value={this.state.fundsToAdd}
                        autoFocus
                        onChange={e => this.setState({ fundsToAdd: e.target.value })}
                        
                    />
                </Grid>
            </Grid>
        </form>
        );

        const paypalButtons = (
            <>
            {accountForm}
            <Box mt={3}></Box>
            <PayPalButton
                amount={this.state.fundsToAdd}
                // amount="0.01"
                // shippingPreference="NO_SHIPPING" // default is "GET_FROM_FILE"
                onSuccess={(details, data) => {
                    // alert("Transaction completed by " + details.payer.name.given_name);
                    console.log(JSON.stringify(details));
                    console.log(JSON.stringify(data));

                    addFunds(this, this.state.username, this.state.fundsToAdd, details, data).then(() => this.props.onProfileReload());
                    
                    this.state.showMessage = true;
                    this.state.fundsAdded = this.state.fundsToAdd;

                    // OPTIONAL: Call your server to save the transaction
                    // return fetch("/paypal-transaction-complete", {
                    //     method: "post",
                    //     body: JSON.stringify({
                    //         orderId: data.orderID
                    //     })
                    // });
                }}
                options={{
                    // clientId: "Ad_0OZGZEYoIOTEnyFLPW1p5As62zz_gKFwYNWxue79MQTSc7dbFtz8awJvPxHmBOAKy8Svhswn0hl-J",       // john doe test store
                    // clientId: "AegcsJlzEC5qT4p84rdRSS4xNUxvTiSv5Die8tFwQmAq-xDB99h5HNe9XWKDPjYvGBDMNSOjCNarcIxH"     // sandbox
                    // clientId: "AeSPrYfXjP70lkYxq4hq10XbF5-vgUT3E8NCoGX9tu0bQtoH_ohriTgY4hiFcIpyhDhPAB9KwU3Fp_cC",       // prod id
                    clientId: paypal_client_id
                }}
                onCancel={(data) => {
                    console.log('Paypal login canceled :' + JSON.stringify(data));
                }}
            />

            <Button variant="contained" fullWidth component={NavLink} to="/">Cancel</Button>
            </>
        );

        return (
            <>
                <Container className={classes.mainGrid}>
                    <Grid container spacing={3} justify='center'>
                        <Grid item xs={12} sm={10} md={8} lg={6}>
                            <Paper className={classes.mainPaper}>
                                <Typography variant="h6" gutterBottom className={classes.formHeader}>
                                    Add Funds
                                </Typography>
                            {this.state.showMessage ? successAlert : paypalButtons}
                            </Paper>
                        </Grid>
                    </Grid>
                </Container>
            </>
        );
    }
}

AddFunds.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(withRouter(AddFunds));
