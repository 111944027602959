import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';

import { withStyles } from '@material-ui/styles';
import { createMuiTheme } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { withRouter } from "react-router";
import { NavLink} from 'react-router-dom';
import { Alert, AlertTitle } from '@material-ui/lab';

import {forgotPassword} from './Api';

const theme = createMuiTheme();
const styles = styleFunc => ({

    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
});

class ForgotPassword extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            email: '',
            message: '',
            emailSent: false,
        };
    }

    handleSubmit(event) {
        event.preventDefault();
        forgotPassword(this);
    }

    render() {

        const { classes } = this.props;

        var msgAlert = (
            <>
            <Box mt={3}></Box>
            <Alert severity="error">
                <AlertTitle>Error</AlertTitle>
                {this.state.message}
            </Alert>
            <Box mt={3}></Box>
            </>
        );

        const forgotPasswordForm = (
            <form className={classes.form} noValidate onSubmit={this.handleSubmit.bind(this)}>
                {this.state.message === '' ? '' : msgAlert}
                <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Email"
                    name="email"
                    autoComplete="email"
                    onChange={e => this.setState({ email: e.target.value.toLowerCase() })}
                    autoFocus
                />
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                >
                Reset Password
                </Button>
            </form>
        );

        const submittedMessage = (
            <Typography component="h1" style={{ textAlign: 'center', fontStyle: 'italic', paddingTop: '20px' }} >
            A password reset link has been sent to {this.state.email}. This link will be valid for 15 minutes. If you don't see the link in your Inbox, please check your Spam folder.
            </Typography>
        );

        return (
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <div className={classes.paper}>
                    <Avatar className={classes.avatar}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Forgot password?
                    </Typography>
                    {this.state.emailSent ? submittedMessage : forgotPasswordForm}
                </div>
                <Box mt={8}>
                </Box>
            </Container>
        );
    }
}

ForgotPassword.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(withRouter(ForgotPassword));
