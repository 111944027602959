import React from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/styles';
import { createMuiTheme } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { withRouter } from "react-router";
import { NavLink } from "react-router-dom";
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import Chip from '@material-ui/core/Chip';
import Divider from '@material-ui/core/Divider';
import {updateUser, getUserInfo, responseCodes, addFunds} from './Api';
import { Alert, AlertTitle } from '@material-ui/lab';


import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';


const theme = createMuiTheme();
const styles = styleFunc => ({
    mainGrid: {
        paddingTop: theme.spacing(8),
        paddingBottom: theme.spacing(8),
    },
    mainPaper: {
        textAlign: 'center',
        paddingTop: "20px",
        paddingBottom: "20px",
        paddingLeft: "50px",
        paddingRight: "50px",
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(3),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },

});

function copyString(value){
    return JSON.parse(JSON.stringify(value === null ? '' : value));
}

class Account extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            username: this.props.username,
            fundsToAdd: '',
            submitResponse: null,
        };
    }
    
    // componentDidMount(){
    //     getUserInfo(this);
    // }

    handleSubmit(event){
        event.preventDefault();
        addFunds(this, this.state.username, this.state.fundsToAdd).then(() => this.props.onProfileReload());
        // updateUser(this).then(() => {
        //     console.log(this.state.submitResponse);
        // });

    }


    render() {

        const { classes } = this.props;


        var msgAlert = (
            <>
            <Alert severity="error">
                <AlertTitle>Error</AlertTitle>
                There was a problem adding funds.
            </Alert>
            <Box mt={3}></Box>
            <Button variant="contained" component={NavLink} to="/">Return</Button>
            </>
        );

        if (this.state.submitResponse === responseCodes.USER.SUCCESS) {
            msgAlert = (
                <>
                <Alert severity="success">
                    <AlertTitle>Success</AlertTitle>
                    Funds added.
                </Alert>
                <Box mt={3}></Box>
                <Button variant="contained" component={NavLink} to="/">Return</Button>
                </>
            );
        }

        const balance = this.props.balance == null ? "0.00" : this.props.balance.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');

        return (
            <>
                <Container className={classes.mainGrid}>
                    <Grid container spacing={3} justify='center'>
                        <Grid item xs={12} sm={10} md={8} lg={6}>
                            <Paper className={classes.mainPaper}>
                                <Typography variant="h6" gutterBottom className={classes.formHeader}>
                                    Account Info
                                </Typography>
                                <Divider />
                                <Box mt={2}></Box>
                                    <Typography variant="overline">Available Funds<br />
                                    {"$" + balance}
                                    </Typography>
                                    <Box mt={1}></Box>
                                <Divider />
                                <Box mt={2}></Box>
                                <Grid container direction="column" spacing={1}>
                                    <Grid item>
                                    <Button component={NavLink} to="/addfunds" variant="contained" color="primary" fullWidth>Add Funds</Button>
                                    </Grid>
                                    <Grid item>
                                    <Button component={NavLink} to="/withdrawfunds" variant="contained" color="secondary" fullWidth>Withdraw Funds</Button>
                                    </Grid>
                                </Grid>
                                <Box mt={2}></Box>
                                <Typography variant="h6" gutterBottom className={classes.formHeader}>
                                    Transaction History
                                </Typography>
                                {/* {this.state.submitResponse === null ? accountForm : msgAlert} */}
                            </Paper>
                        </Grid>
                    </Grid>
                </Container>
            </>
        );
    }
}

Account.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(withRouter(Account));
