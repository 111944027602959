import React from 'react';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/styles';
import { createMuiTheme } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { withRouter } from "react-router";
import Box from '@material-ui/core/Box';
import { Alert, AlertTitle } from '@material-ui/lab';
import TextField from '@material-ui/core/TextField';
import {getUserInfo, getUserBalance, withdrawFunds} from './Api';

const theme = createMuiTheme();
const styles = styleFunc => ({
    mainGrid: {
        paddingTop: theme.spacing(8),
        paddingBottom: theme.spacing(8),
    },
    mainPaper: {
        textAlign: 'center',
        paddingTop: "20px",
        paddingBottom: "20px",
        paddingLeft: "50px",
        paddingRight: "50px",
        width: '100%',
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(3),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
});

class WithdrawFunds extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            username: this.props.username,
            // email: this.props.email,
            email: '',
            fundsToWithdraw: '',
            showMessage: false,
            response: {},
        };
    }

    componentDidMount(){
        // this.props.onProfileReload();
        getUserInfo(this);
    }

    handleSubmit(event) {
        event.preventDefault();
        // this.state.showMessage = true;
        withdrawFunds(this, this.state.username, this.state.fundsToWithdraw, this.state.email).then(()=>{
            this.props.reloadBalance();
            this.setState({showMessage: true});            
        });
        console.log('Sending ' + this.state.fundsToWithdraw + ' to ' + this.state.email);
        // createUser(this);
    }

    render() {

        const { classes } = this.props;

        const funds = isNaN(parseFloat(this.state.fundsToWithdraw)) ? "0.00" : parseFloat(this.state.fundsToWithdraw).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');

        const successAlert = (
            <>
            <Box mt={3}></Box>
            <Alert severity="success">
                <AlertTitle>Success</AlertTitle>
                ${funds} was sent to {this.state.email}
            </Alert>
            <Box mt={3}></Box>
            </>
        );

        const withdrawForm = (
            <form className={classes.form} noValidate onSubmit={this.handleSubmit.bind(this)}>

            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TextField
                        // autoComplete="fname"
                        name="fundsToWithdraw"
                        variant="outlined"
                        fullWidth
                        id="fundsToWithdraw"
                        label="Funds To Withdraw"
                        value={this.state.fundsToWithdraw}
                        autoFocus
                        onChange={e => this.setState({ fundsToWithdraw: e.target.value })}
                        
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        name="email"
                        variant="outlined"
                        fullWidth
                        id="email"
                        label="Email to Send Funds To (via PayPal)"
                        value={this.state.email}
                        onChange={e => this.setState({ email: e.target.value })}
                    />
                </Grid>
                <Grid item xs={12}>
                <Button type="submit" variant="contained" color="primary">Submit</Button>
                </Grid>

            </Grid>
        </form>
        );

        return (
            <>
                <Container className={classes.mainGrid}>
                    <Grid container spacing={3} justify='center'>
                        <Grid item xs={12} sm={10} md={8} lg={6}>
                            <Paper className={classes.mainPaper}>
                                <Typography variant="h6" gutterBottom className={classes.formHeader}>
                                Withdraw Funds
                                </Typography>
                                {this.state.showMessage ? successAlert : withdrawForm}
                            </Paper>
                        </Grid>
                    </Grid>
                </Container>
            </>
        );
    }
}

WithdrawFunds.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(withRouter(WithdrawFunds));
