import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/styles';
import {createMuiTheme} from '@material-ui/core/styles';
import {getGames} from './Api';
// import SmallTable from './SmallTable';

import moonPalace from './img/moon_palace.jpeg'
import msg from './img/msg.jpeg'

import {
  NavLink,
} from "react-router-dom";
import grey from "@material-ui/core/colors/grey";
import SmallTable from "./SmallTable";
import {Alert, AlertTitle} from "@material-ui/lab";

const theme = createMuiTheme();

const styles = styleFunc => ({
  icon: {
    marginRight: theme.spacing(2),
  },
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8, 0, 6),
  },
  heroButtons: {
    marginTop: theme.spacing(4),
  },
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  cardMedia: {
    paddingTop: '56.25%', // 16:9
  },
  cardContent: {
    flexGrow: 1,
  },
  cardTitle: {
    textAlign: 'center',
  },
  cardLink: {
    textDecoration: 'none',
  },
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(6),
  },
});

function toDollarFormat(value) {
  return '$' + value.toLocaleString(navigator.language, {minimumFractionDigits: 2});
}


class Games extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      games: [],
    };
  }

  componentDidMount() {
    getGames(this);
  }

  render() {
    const {classes} = this.props;

    console.log('games = ' + JSON.stringify(this.state.games));

    const infoHeaders = [
      {key: 'label', name: 'Label', align: 'left', color: grey[900]},
      {key: 'value', name: 'Value', align: 'right', color: 'primary'},
    ];
    const activeGames = this.state.games.filter(game => game.status !== "Over (pending)");
    var noGamesBox = (
      <Alert severity="info">
        {/*<AlertTitle>INFO</AlertTitle>*/}
        There are no upcoming games. Check back later!
      </Alert>
    );
    return (
      <React.Fragment>
        <Container className={classes.cardGrid} maxWidth="md" style={{ textAlign: 'center' }}>
          <Typography component="h1" variant="h5">
            Upcoming Games
          </Typography>
          {activeGames.length > 0 ? '' : noGamesBox}
          {/* End hero unit */}
          <Grid container spacing={4}>
            {this.state.games.map(game => {
              if (game.status == "Over (pending)") {
                return "";
              }
              const infoData = [
                {label: 'Band', value: game.artist},
                {label: 'Venue', value: game.venue},
                {label: 'Date', value: game.date, isDate: true},
                {label: 'Game', value: game.game_type},
                {label: 'Status', value: game.status},
              ];
              return (
                <>

                  <Grid item key={game.id} xs={12} sm={6} md={4}>
                    <NavLink to={"gameview/" + game.id} className={classes.cardLink}>
                      <Card className={classes.card}>
                        <CardMedia
                          className={classes.cardMedia}
                          image={game.venue == "Moon Palace" ? moonPalace : msg}
                          title="Image title"
                        />
                        <CardContent className={classes.cardContent}>
                          <Typography variant="h5" className={classes.cardTitle}>
                            {game.artist}: {game.game_type}
                          </Typography>
                          <Typography component="h1" style={{ textAlign: 'center', fontStyle: 'italic', paddingTop: '5px' }} >
                            {game.description}
                          </Typography>
                          {/*<Typography variant="h6" className={classes.cardTitle}>*/}
                          {/*    {game.venue_name}*/}
                          {/*</Typography>*/}
                          <br/>
                          <SmallTable headers={infoHeaders} data={infoData} showHeaders={false} minWidth={15}/>
                          <br/>
                          <Typography>
                            Entry Fee: {toDollarFormat(game.entry_fee)}<br/>
                            Players: {game.entries} / {game.max_entries}<br/>
                            Status: {game.status}
                          </Typography>
                        </CardContent>
                      </Card>
                    </NavLink>
                  </Grid>
                </>
              )
            })}
          </Grid>
        </Container>

      </React.Fragment>
    );
  }

}

Games.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Games);
