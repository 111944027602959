import React from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import {NavLink} from 'react-router-dom';

import { withStyles } from '@material-ui/styles';
import { createMuiTheme } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { withRouter } from "react-router";
import logo from './img/logo-large.png';

const theme = createMuiTheme();

const styles = styleFunc => ({

    icon: {
        marginRight: theme.spacing(2),
      },
      heroContent: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(8, 0, 6),
      },
      heroButtons: {
        marginTop: theme.spacing(4),
      },
      cardGrid: {
        paddingTop: theme.spacing(8),
        paddingBottom: theme.spacing(8),
      },
      card: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      },
      cardMedia: {
        paddingTop: '56.25%', // 16:9
      },
      cardContent: {
        flexGrow: 1,
      },
      navLink: {
        textDecoration: 'none'
      },
      footer: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(6),
      },
});

class SignupBanner extends React.Component {

  render() {

    const { classes } = this.props;
    if (this.props.location.pathname !== "/"){
        return "";
    }
    return (
        <React.Fragment>
        <div className={classes.heroContent}>
        {/* <Container maxWidth="sm"> */}
        <Container>
        <Typography component="h1" variant="h4" align="center" color="textPrimary" gutterBottom>
            <img src={logo} />
        </Typography>
        <Typography variant="h5" align="center" color="textSecondary" paragraph>
        Fantasy setlist games for your favorite jam bands!
        </Typography>
        <Typography variant="h6" align="center" color="textSecondary" paragraph>
        (<i>beta version</i>)
        </Typography>
        <div className={classes.heroButtons}>
            <Grid container spacing={2} justify="center">
            <Grid item>
            <NavLink to="/signup" className={classes.navLink}>
                <Button variant="contained" color="primary">
                Sign Up
                </Button>
            </NavLink>
            </Grid>
            <Grid item>
            <NavLink to="/login" className={classes.navLink}>
                <Button variant="outlined" color="primary">
                Login
                </Button>
                </NavLink>
            </Grid>
            </Grid>
        </div>
        </Container>
    </div>
    </React.Fragment>
  );
  }
}

SignupBanner.propTypes = {
    classes: PropTypes.object.isRequired,
  };
  
export default withStyles(styles)(withRouter(SignupBanner));
