
const api_version = 'v1';
const api_url = process.env.NODE_ENV === 'development' ? process.env.REACT_APP_API_URL_LOCAL : process.env.REACT_APP_API_URL
const api_base = api_url + '/' + api_version + '/';
console.log('Using api url ' + api_base);

// TODO: Share this in a json with the flask api.
export const responseCodes = {
    USER: {
        SUCCESS: 'SUCCESS',
        SUCCESS_CREATE: 'SUCCESS_CREATE',
        USER_EXISTS: 'USER_EXISTS',
        USER_NOT_FOUND: 'USER_NOT_FOUND',
        VALID_TOKEN: 'VALID_TOKEN',
        EXPIRED_TOKEN: 'EXPIRED_TOKEN',
        INVALID_TOKEN: 'INVALID_TOKEN',
        REVOKED_TOKEN: 'REVOKED_TOKEN',
        VALID_LOGIN: 'VALID_LOGIN',
        INVALID_LOGIN: 'INVALID_LOGIN',
        SUCCESS_LOGOUT: 'SUCCESS_LOGOUT',
    },
    GAME: {
        SUCCESS: 'SUCCESS',
        GAME_FULL: 'GAME_FULL',
        GAME_LIVE: 'GAME_LIVE',
        GAME_OVER: 'GAME_OVER',
        GAME_DONE: 'GAME_DONE',
        INVALID_ENTRY: 'INVALID_ENTRY',
        INSUFFICIENT_FUNDS: 'INSUFFICIENT_FUNDS',
        ERROR: 'ERROR',
        ALREADY_ENTERED: 'ALREADY_ENTERED',
    },
}

function getHeaders() {
    return { 'Authorization': 'Bearer ' + localStorage.getItem('token') };
}

export function getTokenStatus(caller) {
    const url = api_base + 'token-status';
    return fetch(url, { method: 'POST', headers: getHeaders() })
        .then(res => res.json())
        .then(
            (result) => {
                const auth = (result.response_code === responseCodes.USER.VALID_TOKEN) || (result.response_code === responseCodes.USER.VALID_LOGIN);
                caller.setState({
                    authMessage: result.message,
                    username: result.username,
                    auth
                });
                console.log('Got token status, auth = ' + JSON.stringify(auth) + ' response_code = ' + result.response_code);
            },
            (error) => {
                console.error('Error getting token status: ' + JSON.stringify(error));
                caller.setState({
                    auth: false,
                    error
                })
            }
        );
}

export function getUserInfo(caller) {
    const url = api_base + 'user';
    const data = { username: caller.state.username };
    console.log('calling getUserInfo: ' + JSON.stringify(data));
    return fetch(url, { headers: getHeaders(), method: 'POST', body: JSON.stringify(data) })
        .then(res => res.json())
        .then(
            (result) => {
                console.log('userInfo = ' + JSON.stringify(result));
                if (result.response_code === responseCodes.USER.SUCCESS) {
                    caller.setState({
                        username: result.username,
                        accountBalance: result.balance,
                        firstName: result.first_name,
                        lastName: result.last_name,
                        email: result.email,
                    });
                }
            },
            (error) => {
                caller.setState({
                    error
                });
            }
        )
}

export function getUserBalance(caller) {
    const url = api_base + 'user';
    const data = { username: caller.state.username };
    console.log('calling getUserInfo');
    return fetch(url, { headers: getHeaders(), method: 'POST', body: JSON.stringify(data) })
        .then(res => res.json())
        .then(
            (result) => {
                console.log('userInfo = ' + JSON.stringify(result));
                if (result.response_code === responseCodes.USER.SUCCESS) {
                    caller.setState({
                        accountBalance: result.balance,
                    });
                }
            },
            (error) => {
                caller.setState({
                    error
                });
            }
        )
}

export function createUser(caller) {
    const url = api_base + 'create-user';
    
    const data = {
        first_name: caller.state.firstName,
        last_name: caller.state.lastName,
        email: caller.state.email,
        username: caller.state.username,
        password: caller.state.password,
        activationCode: caller.state.activationCode,
    }

    return fetch(url, { headers: getHeaders(), method: 'POST', body: JSON.stringify(data) })
        .then(res => res.json())
        .then(
            (result) => {
                // Check other response codes here
                if (result.response_code === responseCodes.USER.SUCCESS_CREATE) {

                    localStorage.setItem('token', result.token);
                    const { onLogin } = caller.props;
                    onLogin("Welcome " + caller.state.firstName + ", your account has been created!");
                    caller.props.history.push('/');
                }
                else {
                    caller.setState({
                        message: result.message,
                    })
                }

            },
            (error) => {
                caller.setState({
                    error
                });
            }
        )
}

export function updateUser(caller) {
    const url = api_base + 'update-user';
    const data = {
        username: caller.state.username,
        first_name: caller.state.firstName,
        last_name: caller.state.lastName,
        email: caller.state.email,
        password: caller.state.password,
    };
    console.log('Sending user info = ' + JSON.stringify(data));
    return fetch(url, { headers: getHeaders(), method: 'POST', body: JSON.stringify(data) })
        .then(res => res.json())
        .then(
            (result) => {
                console.log('userInfo = ' + JSON.stringify(result));
                if (result.response_code === responseCodes.USER.SUCCESS) {
                    caller.setState({
                        submitResponse: result.response_code
                    });
                }
            },
            (error) => {
                caller.setState({
                    error
                });
            }
        )
}

export function logoutUser(caller) {
    const url = api_base + 'logout';

    fetch(url, { headers: getHeaders(), method: 'POST' })
        .then(res => res.json())
        .then(
            (result) => {
                if (result.response_code === responseCodes.USER.SUCCESS_LOGOUT) {
                    caller.setState({
                        auth: false,
                        openSnackbox: true,
                        snackboxMsg: result.message,
                    });
                }
            },
            (error) => {
                caller.setState({
                    error
                });
            }
        )
}

export function loginUser(caller) {
    const url = api_base + 'login';

    const data = {
        username: caller.state.username,
        password: caller.state.password,
    }

    fetch(url, { headers: getHeaders(), method: 'POST', body: JSON.stringify(data)})
        .then(res => res.json())
        .then(
            (result) => {
                if (result.response_code === responseCodes.USER.VALID_LOGIN) {
                    localStorage.setItem('token', result.token);
                    caller.props.onLogin(result.message);
                    caller.props.history.push('/');
                }
                else{
                    caller.setState({
                        message: result.message,
                    })
                }
                // Handle bad login message here
            },
            (error) => {
                caller.setState({
                    error
                });
            }
        )
}

export function forgotPassword(caller) {
    const url = api_base + 'forgot-password';

    const data = {
        email: caller.state.email,
    }

    fetch(url, { headers: getHeaders(), method: 'POST', body: JSON.stringify(data)})
        .then(res => res.json())
        .then(
            (result) => {
                if (result.response_code === responseCodes.USER.SUCCESS) {
                    caller.setState({
                        emailSent: true,
                        openSnackbox: true,
                        snackboxMsg: result.message,
                    });
                }
            },
            (error) => {
                caller.setState({
                    error
                });
            }
        )
}

export function resetPassword(caller, token) {
    const url = api_base + 'reset-password';

    const data = {
        password: caller.state.password,
        token: token,
    }

    fetch(url, { headers: getHeaders(), method: 'POST', body: JSON.stringify(data)})
        .then(res => res.json())
        .then(
            (result) => {
                if (result.response_code === responseCodes.USER.SUCCESS) {
                    caller.setState({
                        passwordReset: true,
                        openSnackbox: true,
                        snackboxMsg: result.message,
                    });
                }
                else{
                  caller.setState({
                    message: result.message,
                  })
                }
            },
            (error) => {
                caller.setState({
                    error
                });
            }
        )
}

export function getGames(caller) {
    const url = api_base + 'games';
    fetch(url)
        .then(res => res.json())
        .then(
            (result) => {
                // console.log('games result = ' + JSON.stringify(result));
                if (Array.isArray(result)){
                    caller.setState({
                        games: result
                    });    
                }
            },
            (error) => {
                // console.log('games error = ' + JSON.stringify(error));
                caller.setState({
                    error
                });
            }
        )
}

export function getGame(caller, id) {
    const url = api_base + 'games?id=' + id;
    return fetch(url)
        .then(res => res.json())
        .then(
            (result) => {
                console.log('game = ' + JSON.stringify(result));
                caller.setState({
                    game: result[0]
                });
            },
            (error) => {
                caller.setState({
                    error
                });
            }
        );
}

export function getSongs(caller) {
    const url = api_base + 'songs';
    return fetch(url)
        .then(res => res.json())
        .then(
            (result) => {
                caller.setState({
                    songs: result
                });
            },
            (error) => {
                caller.setState({
                    error
                });
            }
        )
}


export function usernameExists(caller, username) {
    const url = api_base + 'username-exists?username=' + username;
    return fetch(url)
        .then(res => res.json())
        .then(
            (result) => {
                caller.setState({
                    usernameExists: result.exists
                });
            },
            (error) => {
                caller.setState({
                    error
                });
            }
        )
}


export function submitGameEntry(caller, game_id, username, songs, is_edit) {
    const url = api_base + 'submit-entry';
    const data = {
        'game_id': game_id,
        'username': username,
        'songs': songs,
        'is_edit': is_edit,
    }
    return fetch(url, { headers: getHeaders(), method: 'POST', body: JSON.stringify(data) })
        .then(res => res.json())
        .then(
            (result) => {
                caller.setState({
                    formSubmitted: true,
                    formResponse: result,
                });
            },
            (error) => {
                caller.setState({
                    error
                });
            }
        )
}

export function cancelGameEntry(caller, game_id, username) {
    const url = api_base + 'cancel-entry';
    const data = {
        'game_id': game_id,
        'username': username,
    }
    return fetch(url, { headers: getHeaders(), method: 'POST', body: JSON.stringify(data) })
        .then(res => res.json())
        .then(
            (result) => {
                caller.setState({
                    formSubmitted: true,
                    formResponse: result,
                });
            },
            (error) => {
                caller.setState({
                    error
                });
            }
        )
}

export function addFunds(caller, username, amount, txnDetails, txnData) {
    const url = api_base + 'add-funds';
    const data = {
        'username': username,
        'amount': amount,
        'details': txnDetails,
        'data': txnData,
    }
    console.log('Sending funds: ' + JSON.stringify(data));
    return fetch(url, { headers: getHeaders(), method: 'POST', body: JSON.stringify(data) })
        .then(res => res.json())
        .then(
            (result) => {
                console.log('add funds response = ' + JSON.stringify(result));
                caller.setState({
                    submitResponse: result.response_code,
                });
            },
            (error) => {
                caller.setState({
                    error
                });
            }
        )
}

export function getUserEntries(caller, username) {
    const url = api_base + 'user-entries';
    const data = {'username': username}
    return fetch(url, { headers: getHeaders(), method: 'POST', body: JSON.stringify(data) })
        .then(res => res.json())
        .then(
            (result) => {
                console.log('user entries = ' + JSON.stringify(result));
                caller.setState({
                    activeGames: result.entries.active,
                    doneGames: result.entries.done,
                });
            },
            (error) => {
                caller.setState({
                    error
                });
            }
        )
}

export function getUserGameEntry(caller, username, gameID) {
    const url = api_base + 'user-game-entry';
    const data = {'username': username, 'game_id': gameID}
    return fetch(url, { headers: getHeaders(), method: 'POST', body: JSON.stringify(data) })
        .then(res => res.json())
        .then(
            (result) => {
                console.log('user game entry = ' + JSON.stringify(result));
                if(result.response_code == responseCodes.GAME.SUCCESS){
                    caller.setState({
                        existingEntries: result.entries,
                        alreadyEntered: true,
                    });    
                }
            },
            (error) => {
                caller.setState({
                    error
                });
            }
        )
}

export function withdrawFunds(caller, username, amount, email) {
    const url = api_base + 'withdraw-funds';
    const data = {
        'username': username,
        'amount': amount,
        'email': email,
    }
    console.log('Withdrawing funds: ' + JSON.stringify(data));
    return fetch(url, { headers: getHeaders(), method: 'POST', body: JSON.stringify(data) })
        .then(res => res.json())
        .then(
            (result) => {
                console.log('withdraw funds response = ' + JSON.stringify(result));
                caller.setState({
                    response: result,
                });
            },
            (error) => {
                caller.setState({
                    error
                });
            }
        )
}
