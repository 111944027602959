import React from 'react';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/styles';
import { createMuiTheme } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { withRouter } from "react-router";

const theme = createMuiTheme();
const styles = styleFunc => ({
    mainGrid: {
        paddingTop: theme.spacing(8),
        paddingBottom: theme.spacing(8),
    },
    mainPaper: {
        textAlign: 'center',
        paddingTop: "20px",
        paddingBottom: "20px",
        paddingLeft: "50px",
        paddingRight: "50px",
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(3),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
});

class Temp extends React.Component {

    render() {

        const { classes } = this.props;

        return (
            <>
                <Container className={classes.mainGrid}>
                    <Grid container spacing={3} justify='center'>
                        <Grid item xs={6}>
                            <Paper className={classes.mainPaper}>
                                <Typography variant="h6" gutterBottom className={classes.formHeader}>
                                Temp
                                </Typography>
                            </Paper>
                        </Grid>
                    </Grid>
                </Container>
            </>
        );
    }
}

Temp.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(withRouter(Temp));
